/* Navbar.css */

/* Add animation for sliding menu */
.menu-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1000;
  }
  
  .menu-content {
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .slide-open {
    transform: translateX(0%);
  }
  
  .menu-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 1
  }  
  .Navbar {
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity value (0.5 for 50% opacity) */
  }
  
  .Navbar a {
    color: white; /* Set the text color */
  }
  